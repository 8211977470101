<template>
  <div class="exhibit">
    <div class="text-title">
      <i>广州海事博物馆三维模型</i>
      <p>
        集成高精度三维激光扫描、高清摄影测量等多种测量传感器技术，获取完整而精细的三维数字模型
      </p>
    </div>
    <div class="exhibit-img">
      <span
        v-for="item of exhibitionDataStat"
        :key="item.id"
        @click="jump(item)"
      >
        <div class="imgs">
          <img
            :src="
              baseIP +
              imageShowAPI +
              item.haiShiDataImageList[0].images.thumbnailThreeUri
            "
            alt=""
          />
        </div>
        <h4>{{ item.title }}</h4>
      </span>
    </div>
    <div class="btn">
      <span @click="moreData"
        ><em class="el-icon-arrow-down"></em> 查看更多</span
      >
    </div>
  </div>
</template>

<script>
import { getColumnContent, getsearchHaiShiData } from "../api/index.js";
import store from "@/store";
export default {
  data() {
    return {
      baseIP: process.env.VUE_APP_BASE_URL,
      imageShowAPI: "/yuan/files/getFileStream/",
      exhibitionData: [],
      exhibitionDataStat: [],
      route: "exhibition",
    };
  },
  created() {
    // 三维模型
    getsearchHaiShiData({ type: "2" }).then((res) => {
      console.log(res, "三维模型");
      if (res.code === 0) {
        this.exhibitionData = res.data.content;
        this.exhibitionDataStat = this.exhibitionData.slice(0, 6);
      }
    });
  },
  methods: {
    moreData() {
      const length = this.exhibitionData.length;
      if (length > this.exhibitionDataStat.length) {
        this.exhibitionDataStat = this.exhibitionData.slice(
          0,
          this.exhibitionDataStat.length + 2
        );
      } else {
        this.$toast.show("没有更多数据了", 2000);
      }
    },
    jump(item){
       if(item.url.indexOf('http')!=-1){
        window.open(item.url)
      }else{
        window.open(`https://${item.url}`)
      }
    }
    // details(item) {
    //   this.$router.push({
    //     name: "news",
    //     query: { itemData: item, route: this.route },
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
.text-title {
  width: 665px;
  line-height: 120px;
  margin: 0 auto;
  margin-bottom: 72px;
  box-sizing: border-box;
  i {
    display: block;
    font-style: normal;
    height: 40px;
    line-height: 40px;
    font-size: 36px;
    color: #333333;
  }
  p {
    line-height: 40px;
    font-size: 22px;
    color: #999999;
    margin: 10px 0;
  }
}
.exhibit-img {
  display: flex;
  justify-content: center;
  flex-flow: wrap;
  span {
    display: inline-block;
    width: 710px;
    height: 450px;
    margin-bottom: 45px;
    .imgs {
      width: 100%;
      // height: 430px;
      height: 402px;
      display: flex; // 居中新加的
      align-items: center; // 居中新加的
      justify-content: center; // 居中新加的
      overflow: hidden;
      background-color: #f7f7f7;
      img {
        // width: 100%;
        // height: 100%;
        display: block;
        max-width: 100%; // 居中新加的
        max-height: 100%; // 居中新加的
      }
    }
    h4 {
      font-weight: 400;
      height: 54px;
      line-height: 54px;
      text-align: left;
      font-size: 22px;
      color: #333333;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      box-sizing: border-box;
      border-bottom: 1px solid #f1f1f1;
    }
  }
  span:last-child {
    margin-bottom: 0;
  }
}
.btn {
  margin-top: 20px;
  height: 80px;
  line-height: 80px;
  margin-bottom: 10px;
  span {
    display: inline-block;
    // width: 150px;
    height: 40px;
    line-height: 40px;
    font-size: 22px;
    color: #999999;
    em {
      margin-right: 10px;
    }
  }
}
</style>